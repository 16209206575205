import logo from './MEDIA/Orange.png';
import picMain from './MEDIA/PicAsh.JPG'
import pic1 from './MEDIA/Pic1.jpg'
import pic2 from './MEDIA/Pic2.jpg'
import pic3 from './MEDIA/Pic3.jpg'
import pic4 from './MEDIA/Pic4.jpg'
import pic5 from './MEDIA/Pic5.jpg'
import './App.css';
import { data } from './data';
import { useState } from 'react';

function App() {

  const [language, setLanguage] = useState('en');

  return (
    <div className="App container-fluid">
      <Navigation lang={language} toggleLang={setLanguage} />
      <Home lang={language} />
      <ContentCard lang={language} imgSrc={pic1} id='philosophy' title={data[language].philosophyTitle} text={data[language].philosophyText} />
      <Quotes lang={language} imgSrc={pic2} />
      <div className='courses' id='book'>
        {data[language].courseTitles.map((el, i) => {
          return (
            <CourseCard lang={language} courseTitle={el} coursePrice={data[language].coursePrices[i]} courseDiscount={data[language].courseDiscount} courseEmailSubject={data[language].courseEmailSubject[i]} />
          )
        })}
      </div>
      <ContentCard lang={language} imgSrc={pic3} id='about' title={data[language].aboutTitle} text={data[language].aboutText} />
      <div className='d-md-flex container gap-3 justify-content-center'>
        <img className='content-picture' src={pic4} alt="Asher" style={{ margin: '1rem 0' }} />
        <img className='content-picture' src={pic5} alt="mdh" style={{ margin: '1rem 0' }} />
      </div>
      <Contact lang={language} />
      <img src={logo} alt="logo" style={{ height: '75px', marginRight: '1rem' }} />
    </div>
  );
};

const Navigation = ({ lang, toggleLang }) => {

  return (
    <nav className="navbar fixed-top navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="#home" style={{ fontFamily: 'Cormorant, serif', fontSize: '2rem' }}>
          <img id='logo' src={logo} alt="logo" style={{ height: '35px', margin: 'auto 1rem auto 0' }} />
          Asher Varadi
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 col-12 justify-content-end">
            {data[lang].navLinks.map((el, i) => {
              return (
                <li key={i} className='nav-item'>
                  <a href={'#'.concat('', data.en.navLinks[i])} className='nav-link' aria-current='page'>{el.toUpperCase()}</a>
                </li>
              )
            })}
            <li className='nav-item'>
              <a className='nav-link' aria-current='page' target='_blank' rel="noreferrer" href="https://breathingcoordination.ch/">
                MDH</a>
            </li>
            <li className='nav-item' onClick={() => lang === 'en' ? toggleLang('fr') : toggleLang('en')}>
              <p className='nav-link language-toggle'>
                <span style={{ fontWeight: lang === 'en' ? '600' : 'normal' }}>EN</span>
                /
                <span style={{ fontWeight: lang === 'fr' ? '600' : 'normal' }}>FR</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </nav >
  );
};

const Home = ({ lang }) => {
  return (
    <div className='Home section' id='home'>
      <img src={picMain} alt="Asher" className='content-picture' />
      <div className='title-quote-container'>
        <h1 className='title-quote'>{data[lang].titleQuote}</h1>
      </div>
    </div>
  );
};

const ContentCard = ({ imgSrc, title, text, id }) => {

  return (
    <div className='content-card section'>
      <img className='content-picture' src={imgSrc} alt="content" />
      <div className='text-container' id={id}>
        <h1 style={{ textAlign: 'left' }}>{title}</h1>
        {text.map((el, i) => {
          return (
            <p style={{ textAlign: 'left' }} key={i}>{el}</p>
          )
        })}
      </div>
    </div>
  );
};

const Quotes = ({ lang, imgSrc }) => {
  return (
    <div>
      <img className='content-picture' src={imgSrc} alt="content" />
      <div className='d-lg-flex quotes-container container gap-3 col-12'>
        {data[lang].quotesNames.map((el, i) => {
          return (
            <QuoteCard name={el} role={data[lang].quotesRoles[i]} quote={data[lang].quotesText[i]} />
          )
        })}
      </div>
    </div>
  );
};

const QuoteCard = ({ name, role, quote }) => {
  return (
    <div className='quote-card col-lg-4' style={{ margin: '1rem auto' }}>
      <h2 className='quote-title' style={{ textAlign: 'left', fontWeight: '600', fontSize: '34px' }}>{name.toUpperCase()}</h2>
      <h5 style={{ textAlign: 'left', fontWeight: '400', fontSize: '1rem' }}>{role}</h5>
      <p className='quote-text' style={{ textAlign: 'left' }}>{'"'.concat(quote, '"')}</p>
    </div>
  );
};

const CourseCard = ({ lang, courseTitle, coursePrice, courseDiscount, courseEmailSubject }) => {
  return (
    <div className='course-card' style={{ textAlign: 'left' }}>
      <h1>{courseTitle}</h1>
      <p>{coursePrice}</p>
      <p>{courseDiscount}</p>
      <button onClick={() => window.location = courseEmailSubject}>{lang === 'en' ? 'Book' : 'Réserver'}</button>
    </div>
  );
};

const Contact = ({ lang }) => {
  return (
    <div id='contact' style={{ textAlign: 'left' }}>
      <h1>Contact</h1>
      <p>{lang === 'en' ? 'For any enquiries, please contact me via email' : 'Pour toute demande de renseignements'}</p>
      <a href="mailto:info@ashervaradi.ch">info@ashervaradi.ch</a>
    </div>
  );
};

export default App;
